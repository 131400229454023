<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
import btnComponent from "@/components/btnComponent";
// import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ส่งมอบรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
    btnComponent,
  },
  data() {
    return {
      // tableData: tableData,

      title: "ส่งมอบรถ",
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "ส่งมอบรถ",
          href: "/vehicle-apv"
          
        },
        {
          text: "เพิ่มใบส่งมอบรถ",
          active: true,
        },
      ],
      brand: "",
      bookingDcPrice: 0,
      // branchId:'',
      bookingDate: "",
      qnDetails: "",
      part: "",
      dcPerAccy: 0,
      amountAccy: 1,
      nameState: null,
      typeQnDetails: "ACCY",
      bookingVatPer: 7.0,
      bookingDcPer: 0,
      bookingNetPrice: 0,
      bookingVatPrice: 0,
      bookingGrandTotalPrice: 0,
      resalert: "",
      rowsPO: [],
      qnTotalPrice: 0,
      qnPartTotalPrice: 0,
      qnPartDcPer: 0,
      qnPartDcPrice: 0,
      qnPartNetPrice: 0,
      qnExtraTotalPrice: 0,
      qnExtraDcPer: 0,
      qnExtraDcPrice: 0,
      qnExtraNetPrice: 0,
      plateId: "",

      qnDcPrice: 0,
      rowRedplate: [],

      qnVatPer: 7.0,
      qnDcPer: 0,
      qnNetPrice: 0,
      qnVatPrice: 0,
      qnGrandTotalPrice: 0,

      branchIdSearch: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      interiorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      customerG: "",
      loading: false,
      pageOptions: [10, 25, 50, 100],

      dropzoneOptions: {
        url:
          `${appConfig.api.baseURL}api/credit-inquire/upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles: "image/*",
        uploadMultiple: false,
        paramName: "image",
        init: function() {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // console.log(formData);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณาตรวจสอบข้อมูลอีกครั้ง",

      userBranch: "",
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlagMessage: false,
      overlayFlagAddMessage: false,
      branch: "",
      rowProducts: [],
      rowsCustomers: [],
      rowsFin: [],
      rowsAct: [],
      rowsCampiagn: [],
      rowsExColor: [],
      rowsInColor: [],
      rowsAccy: [],
      rowsExtra: [],
      optionBranch: [],
      qnAccy: [],
      qnPm: [],
      qnOth: [],
      filter: {
        bookingCode: "",
        qnCode: "",
        inquireCode: "",
        customer: "",
        startDate: "",
        endDate: "",
        status: "",
        userBranchId: "",
      },
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "qnCode",
          sortable: true,
          label: "qnCode",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสจอง",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        {
          key: "bookingDate",
          sortable: true,
          label: "วันที่จอง",
        },
        {
          key: "grandTotalPrice",
          label: "ราคา",
        },
        {
          key: "status",
          label: "สถานะ",
          class: "text-center",
        },
        {
          key: "branchName",
          label: "สาขา",
        },
      ],
      fieldsInst: [
        {
          key: "periodNo",
          label: "งวดที่",
        },
        {
          key: "dueDate",
          label: "วันที่ชำระ",
        },
        {
          key: "price",
          label: "ยอดชำระ",
        },
        {
          key: "vatPrice",
          label: "% ภาษี",
        },
        {
          key: "totalPrice",
          label: "สุทธิ",
        },
        // {
        //   key: "action",
        //   label: "ลบ",
        // },
      ],
      fieldsGuarantor: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "customerNameTh",
          label: "ชื่อผู้ค้ำประกัน",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุลผู้ค้ำประกัน",
        },
        // {
        //   key: "action",
        //   label: "ลบ",
        // },
      ],
      rowSaleCondition: [
        {
          scId: "C",
          scName: "เงินสด",
        },
        {
          scId: "I",
          scName: "เช่าซื้อ / ผ่อน",
        },
        {
          scId: "CD",
          scName: "สด / เครดิต",
        },
        {
          scId: "OI",
          scName: "เช่าซื้อสถาบันอื่น ๆ",
        },
      ],
      postType: [
        {
          ptId: "R",
          nameTh: "รับเอง",
        },
        {
          ptId: "S",
          nameTh: "จัดส่ง",
        },
      ],
      taxTypeOptions: [
        {
          ttId: "T",
          nameTh: "มีภาษี",
        },
        {
          ttId: "NT",
          nameTh: "ไม่มีภาษี",
        },
      ],

      activity: "",

      approveNote: "",
      inquireCode: "",
      inquireDate: "",
      status: "",
      statusSale: "",
      inquireNote: "",
      inquireNoteCreate: "",
      rowsMessage: [],
      textMessage: "",

      bookingAccy: [],
      bookingPm: [],
      bookingOth: [],
      rowsGuarantor: [],
      rowsInstallment: [],
      rowsInst: [],

      image: null,
      rowsImage: [],

      ttAccy: 0,
      ttPm: 0,
      ttOTH: 0,

      totalPricex: 0,
      bookingTotalPrice: 0,
      bookingPartTotalPrice: 0,
      bookingPartDcPer: 0,
      bookingPartDcPrice: 0,
      bookingPartNetPrice: 0,
      bookingExtraTotalPrice: 0,
      bookingExtraDcPer: 0,
      bookingExtraDcPrice: 0,
      bookingExtraNetPrice: 0,

      vehicleTotalPrice: 0,
      vehicleDcPer: 0,
      vehicleDcPrice: 0,
      vehicleNetPrice: 0,
      partTotalPrice: 0,
      partDcPer: 0,
      partDcPrice: 0,
      partNetPrice: 0,
      extraTotalPrice: 0,
      extraDcPer: 0,
      extraDcPrice: 0,
      extraNetPrice: 0,
      totalPrice: 0,
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 7,
      vatPrice: 0,
      grandTotalPrice: 0,
      saleCondition: "",
      downPayment: 0,
      totalFinancing: 0,
      fiId: "",
      interest: 0,
      numPeriods: 0,
      installment: 0,
      note: "",
      prodId: "",
      actId: "",
      exteriorColorId: "",
      interiorColorId: "",
      campaignId: "",
      statusQn: "",
      accyCode: "",
      partQnDt: [],
      partQnDtSum: [],
      exCode: "",
      selectedExtra: "",
      ex: "",
      recommenderId: "",
      addrId: "",
      vehicleId: "",
      referralFee: 0,
      licensePlateDelivery: "",
      productDelivery: "",
      taxType: "",
      installmentDate: "",
      rowsVm: [],
      rowsArr: [],
    };
  },
  validations: {
    bookingDate: {
      required,
    },
    branch: {
      required,
    },
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    prodId: {
      required,
    },
    activity: {
      required,
    },
    exteriorColorId: {
      required,
    },
    interiorColorId: {
      required,
    },
    campaignId: {},
    vehicleTotalPrice: {
      required,
    },
    vehicleDcPer: {
      required,
    },
    vehicleDcPrice: {
      required,
    },
    vehicleNetPrice: {
      required,
    },
    downPayment: {
      required,
    },
    totalFinancing: {
      required,
    },
    saleCondition: {
      required,
    },
    numPeriods: {
      required,
    },
    installment: {
      required,
    },
    interest: {
      required,
    },
    fiId: {
      required,
    },
    note: {},
    recommenderId: {},
    addrId: {},
    referralFee: {
      required,
    },
    licensePlateDelivery: {
      required,
    },
    productDelivery: {
      required,
    },
    taxType: {
      required,
    },
    installmentDate: {
      required,
    },
    vehicleId: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.saleId) {
      this.showDetail();
    }
  },
  created() {
    this.getLocalData();
    this.showDetail();
    this.getRedplate();
  },
  methods: {
    customLabelCustomerArr({
      buildingNumber,
      districtNameTh,
      amphurNameEn,
      provinceNameTh,
      postCode,
    }) {
      return `บ้านเลขที่ ${buildingNumber == null ? "-" : buildingNumber} 
      ตำบล ${districtNameTh == null ? "-" : districtNameTh} , 
      อำเภอ ${amphurNameEn == null ? "-" : amphurNameEn},  
       ${provinceNameTh == null ? "-" : provinceNameTh} , 
       ${postCode == null ? "-" : postCode}`;
    },
    customLabelVehicle({
      stockNameTh,
      vin,
      productNameTh,
      engineNumber,
      chassisNumber,
      exteriorColorNameTh,
    }) {
      return `[${stockNameTh != null ? stockNameTh : "-"}]  
      
      ${productNameTh} 
      - สีภายนอก : ${exteriorColorNameTh == null ? "-" : exteriorColorNameTh}, 
      เลขตัวถัง : ${vin == null ? "-" : vin} ,
        เลขเครื่อง :${engineNumber == null ? "-" : engineNumber},
        เลขคัสซี : ${chassisNumber == null ? "-" : chassisNumber}`;
    },

    changeType(type) {
      this.typeQnDetails = type;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    delGuarantor(guarantorId) {
      useNetw
        .delete("api/vehicle-booking/guarantor/delete", {
          data: {
            guarantorId: guarantorId,
            // partId: rowRT.partId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    conformDelInst(iperiodId) {
      Swal.fire({
        title: "ต้องการลบงวดการชำระใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delInst(iperiodId);
        }
      });
    },

    calTotal() {
      this.ttAccy = 0;
      this.ttOTH = 0;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });
      this.partTotalPrice = parseFloat(this.ttAccy);
      this.extraTotalPrice = parseFloat(this.ttOTH);

      this.vehicleDcPrice =
        parseFloat(this.vehicleTotalPrice) *
        parseFloat(this.vehicleDcPer / 100);
      this.vehicleNetPrice =
        parseFloat(this.vehicleTotalPrice) - parseFloat(this.vehicleDcPrice);
      this.partDcPrice =
        parseFloat(this.ttAccy) * parseFloat(this.partDcPer / 100);
      this.partNetPrice =
        parseFloat(this.ttAccy) - parseFloat(this.partDcPrice);
      this.extraDcPrice =
        parseFloat(this.ttOTH) * parseFloat(this.extraDcPer / 100);
      this.extraNetPrice =
        parseFloat(this.ttOTH) - parseFloat(this.extraDcPrice);

      var qnTotalPrice, qnDcPrice, qnNetPrice, qnVatPrice, qnGrandTotalPrice;

      qnTotalPrice =
        parseFloat(this.vehicleNetPrice) +
        parseFloat(this.partNetPrice) +
        parseFloat(this.extraNetPrice);
      this.qnTotalPrice = parseFloat(qnTotalPrice);
      qnDcPrice = parseFloat(this.qnDcPer / 100) * parseFloat(qnTotalPrice);
      this.qnDcPrice = parseFloat(qnDcPrice);
      qnNetPrice = parseFloat(qnTotalPrice) - parseFloat(qnDcPrice);
      this.qnNetPrice = parseFloat(qnNetPrice);
      qnVatPrice = parseFloat(this.qnVatPer / 100) * parseFloat(qnNetPrice);
      this.qnVatPrice = parseFloat(qnVatPrice);
      qnGrandTotalPrice = parseFloat(qnNetPrice) + parseFloat(qnVatPrice);
      this.qnGrandTotalPrice = parseFloat(qnGrandTotalPrice);

      this.totalFinancing =
        parseFloat(this.qnGrandTotalPrice) - parseFloat(this.downPayment);
    },

    showDetail() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-vehicle-sale/show", {
          params: {
            saleId: this.$route.params.saleId,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statusQn = response.data.branchVehicleSale.status;
          this.branch = {
            branchId: response.data.branchVehicleSale.branchId,
            nameTh: response.data.branchVehicleSale.branchName,
          };
          (this.nameTh = {
            cusId: response.data.branchVehicleSale.cusId,
            nameTh: response.data.branchVehicleSale.customerNameTh,
            familyNameTh: response.data.branchVehicleSale.customerFamilyNameTh,
          }),
            (this.bookingDate = response.data.branchVehicleSale.bookingDate);
          this.activity = {
            actId: response.data.branchVehicleSale.actId,
            nameTh: response.data.branchVehicleSale.actId,
          };
          this.prodId = {
            prodId: response.data.branchVehicleSale.prodId,
            prodCode: response.data.branchVehicleSale.brandNameEn,
            nameTh: response.data.branchVehicleSale.productNameTh,
            nameEn: response.data.branchVehicleSale.productNameEn,
          };
          this.exteriorColorId = {
            exteriorColorId: response.data.branchVehicleSale.exteriorColorId,
            nameTh: response.data.branchVehicleSale.exteriorColorNameTh,
            nameEn: response.data.branchVehicleSale.exteriorColorNameEn,
          };
          this.interiorColorId = {
            interiorColorId: response.data.branchVehicleSale.interiorColorId,
            nameTh: response.data.branchVehicleSale.interiorColorNameTh,
            nameEn: response.data.branchVehicleSale.interiorColorNameEn,
          };
          this.campaignId = {
            campaignId: response.data.branchVehicleSale.campaignId,
            nameTh: response.data.branchVehicleSale.campaignNameTh,
            nameEn: response.data.branchVehicleSale.campaignNameEn,
          };
          this.vehicleTotalPrice =
            response.data.branchVehicleSale.vehicleTotalPrice;
          this.vehicleDcPer = response.data.branchVehicleSale.vehicleDcPer;
          this.vehicleDcPrice = response.data.branchVehicleSale.vehicleDcPrice;
          this.vehicleNetPrice =
            response.data.branchVehicleSale.vehicleNetPrice;

          this.saleCondition = {
            scId: response.data.branchVehicleSale.saleCondition,
            scName: response.data.branchVehicleSale.saleCondition,
          };
          this.downPayment = response.data.branchVehicleSale.downPayment;
          this.totalFinancing = response.data.branchVehicleSale.totalFinancing;
          this.fiId = {
            fiId: response.data.branchVehicleSale.fiId,
            nameTh: response.data.branchVehicleSale.fiId,
          };
          this.interest = response.data.branchVehicleSale.interest;
          (this.numPeriods = response.data.branchVehicleSale.numPeriods),
            (this.installment = response.data.branchVehicleSale.installment),
            (this.note =
              response.data.branchVehicleSale.note == null
                ? ""
                : response.data.branchVehicleSale.note);

          this.recommenderId = {
            recommenderId: response.data.branchVehicleSale.recommenderId,
            nameTh: response.data.branchVehicleSale.recommenderNameTh,
            familyNameTh:
              response.data.branchVehicleSale.recommenderFamilyNameTh,
          };
          this.addrId = {
            addrId: response.data.branchVehicleSale.addrId,
          };
          this.vehicleId = {
            vehicleId: response.data.branchVehicleSale.vehicleId,
            stockNameTh: response.data.branchVehicleSale.stockNameTh,
            vin: response.data.branchVehicleSale.vin,
            chassisNumber: response.data.branchVehicleSale.chassisNumber,
            engineNumber: response.data.branchVehicleSale.engineNumber,
            productNameTh: response.data.branchVehicleSale.productNameTh,
            exteriorColorNameTh:
              response.data.branchVehicleSale.exteriorColorNameTh,
          };
          this.referralFee = response.data.branchVehicleSale.referralFee;
          this.licensePlateDelivery = {
            ptId: response.data.branchVehicleSale.licensePlateDelivery,
            nameTh:
              response.data.branchVehicleSale.licensePlateDelivery == "S"
                ? "จัดส่ง"
                : "รับเอง",
          };
          this.productDelivery = {
            ptId: response.data.branchVehicleSale.productDelivery,
            nameTh:
              response.data.branchVehicleSale.productDelivery == "S"
                ? "จัดส่ง"
                : "รับเอง",
          };
          this.taxType = {
            ttId: response.data.branchVehicleSale.taxType,
            nameTh:
              response.data.branchVehicleSale.taxType == "T"
                ? "มีภาษี"
                : "ไม่มีมีภาษี",
          };
          this.installmentDate =
            response.data.branchVehicleSale.installmentDate;

          this.qnDetails = response.data.branchVehicleSaleDetail;
          this.qnDetails.forEach((item) => {
            if (item.type == "ACCY") {
              this.qnAccy.push(item);
            } else if (item.type == "PM") {
              this.qnPm.push(item);
            } else if (item.type == "OTH") {
              this.qnOth.push(item);
            }
          });

          this.rowsInst = response.data.branchVehicleSaleInstallmentPeriod;
          this.rowsGuarantor = response.data.branchVehicleSaleGuarantor;

          this.calTotal();

          this.overlayFlag = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    setProduct(vehicle) {
      this.vehicleTotalPrice = parseInt(vehicle.price);
      this.calProd();
    },

    customLabelProduct({ prodCode, nameEn }) {
      return `[${prodCode}]  ${nameEn}`;
    },
    customLabelCustomer({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    customLabelAccy({ partCode, nameTh }) {
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },

    getRedplate(plate) {
      // this.overlayFlag = true;
      useNetw
        .get("api/approve-vehicle-sale/red-plate", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.branchId,
            licensePlate: plate == undefined ? '' : plate
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowRedplate = response.data.data; //?ข้อมูล
          console.log(this.rowRedplate);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitSalq();
      }
    },

    submitSalq: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/vehicle-booking/update", {
          bookingId: this.$route.params.bookingId,
          branchId: this.branch.branchId,
          cusId: this.nameTh.cusId,
          bookingDate: this.bookingDate,
          vehicleTotalPrice: this.vehicleTotalPrice,
          vehicleDcPer: this.vehicleDcPer,
          vehicleDcPrice: this.vehicleDcPrice,
          vehicleNetPrice: this.vehicleNetPrice,
          partTotalPrice: this.ttAccy,
          partDcPer: 0,
          partDcPrice: 0,
          partNetPrice: this.ttAccy,
          extraTotalPrice: this.ttOTH,
          extraDcPer: 0,
          extraDcPrice: 0,
          extraNetPrice: this.ttOTH,
          totalPrice: this.vehicleNetPrice,
          dcPer: this.bookingDcPer,
          dcPrice: this.bookingDcPrice,
          netPrice: this.vehicleNetPrice,
          vatPer: this.bookingVatPer,
          vatPrice: this.bookingVatPrice,

          grandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          totalFinancing: this.totalFinancing,
          fiId: this.fiId.fiId,
          interest: this.interest,
          numPeriods: this.numPeriods,
          installment: this.installment,
          note: this.note,
          prodId: this.prodId.prodId,
          actId: this.activity.actId,
          exteriorColorId: this.exteriorColorId.exteriorColorId,
          interiorColorId: this.interiorColorId.interiorColorId,
          campaignId: this.campaignId.campaignId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-product-sell",
            // params: {
            //   bookingId: response.data.bookingId,
            // },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    updateDetail: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/credit-inquire/update", {
          saleId: this.$route.params.saleId,
          inquireNote: this.inquireNote,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
    submitDetail: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/approve-vehicle-sale/approve", {
          saleId: this.$route.params.saleId,
          status: "APV",
          plateId:
            this.plateId.plateId == undefined ? "" : this.plateId.plateId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.showDetail();
          this.$router.push({ name: "vehicle-apv" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsPO) {
      this.bookingId = rowsPO[0].bookingId;
      this.$refs["modalCredit"].show();
    },
    submitCredit() {
      this.loading = true;
      useNetw
        .post("api/credit-inquire/store", {
          branchId:
            this.filter.userBranchId != null
              ? this.filter.userBranchId.branchId
              : undefined,
          bookingId: this.bookingId,
          inquireNote: this.inquireNoteCreate,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalCredit"].hide();
          this.$router.push({
            name: "detail-credit-inquire",
            params: { saleId: btoa(response.data.saleId) },
          });
          if (btoa(response.data.saleId)) {
            this.showDetail(btoa(response.data.saleId));
          }
          //   this.showDetail();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>
<style>
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}

.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="card">
        <div
          class="col-12 badge"
          style="background-color: #26B99A"
          v-if="this.statusSale === 'APV'"
        >
          <h4 class="mt-2 text-white">
            อนุมัติแล้ว
          </h4>
        </div>
        <div
          class="col-12 badge"
          style="background-color: #50a5f1"
          v-if="this.statusSale === 'SUBMIT'"
        >
          <h4 class="mt-2 text-white">
            รออนุมัติ
          </h4>
        </div>
        <div
          class="col-12 badge"
          style="background-color: #e74646"
          v-if="this.statusSale === 'NAPV'"
        >
          <h4 class="mt-2 text-white">
            ไม่อนุมัติ
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="!this.$route.params.saleId">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            สาขา:
                            <multiselect
                              v-model="filter.userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              @input="getData"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            รหัสใบจองสินค้า:
                            <b-form-input
                              v-model="filter.bookingCode"
                              type="search"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            &nbsp;

                            <btnComponent
                              @click="handleSearch"
                              changeStyle="search"
                            >
                            </btnComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rowsPO"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected"
                  >
                    <template #cell(index)="rowsPO">
                      {{ rowsPO.index + 1 }}
                    </template>
                    <template #cell(status)="rowsPO">
                      <span
                        v-if="rowsPO.item.status === 'APV'"
                        class="badge bg-success"
                        >ยืนยันแล้ว</span
                      >
                      <span
                        v-if="rowsPO.item.status === 'SUBMIT'"
                        class="badge bg-info"
                        >รออนุมัติ</span
                      >
                      <span
                        v-if="rowsPO.item.status === 'CAN'"
                        class="badge bg-danger"
                        >ยกเลิกแล้ว</span
                      >
                      <span
                        v-if="rowsPO.item.status === 'DFT'"
                        class="badge bg-warning"
                        >ร่าง</span
                      >
                    </template>
                  </b-table>

                  <!-- <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p> -->
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rowsPO.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rowsPO.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card">
            <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <h5>รายละเอียดข้อความ</h5>
                    </div>
                    <div class="col-lg-6 text-end mb-2">
                      <button v-b-modal.modalMessage class="btn btn-primary">
                        เพิ่มข้อความ
                      </button>
                    </div>
                  </div>
                  <div
                    class="table-responsive"
                    v-if="this.rowsMessage.length > 0"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">ข้อความ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in rowsMessage" :key="index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ data.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <h5 class="text-center">ไม่พบข้อมูล</h5>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card" v-if="this.$route.params.saleId">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สาขา<br />
                        <multiselect
                          v-model="branch"
                          :options="localData"
                          disabled
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.branch.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="nameTh"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="auto"
                          :custom-label="customLabelCustomer"
                          disabled
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ :
                        <date-picker
                          v-model="bookingDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.bookingDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.bookingDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.bookingDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> กิจกรรมการขาย :
                        <multiselect
                          v-model="activity"
                          label="nameTh"
                          type="search"
                          :options="rowsAct"
                          open-direction="bottom"
                          placeholder="กิจกรรมการขาย"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.activity.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.activity.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.activity.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> รหัสสินค้า :
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="prodId.prodCode"
                        />
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code> ชื่อสินค้า :
                        <multiselect
                          @input="setProduct(prodId)"
                          v-model="prodId"
                          label="nameEn"
                          type="search"
                          :options="rowProducts"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          disabled
                          :custom-label="customLabelProduct"
                          :class="{
                            'is-invalid': submitform && $v.prodId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-11">
                      <div class="mb-3 position-relative">
                        <code> * </code> รถคันที่เลือก :
                        <multiselect
                          v-model="vehicleId"
                          label="nameEn"
                          type="search"
                          :options="rowsVm"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          disabled
                          :custom-label="customLabelVehicle"
                          :class="{
                            'is-invalid': submitform && $v.vehicleId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.vehicleId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายนอก :
                        <multiselect
                          v-model="exteriorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsExColor"
                          open-direction="bottom"
                          placeholder="สีภายนอก"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.exteriorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.exteriorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.exteriorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายใน :
                        <multiselect
                          v-model="interiorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsInColor"
                          open-direction="bottom"
                          placeholder="สีภายใน"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.interiorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.interiorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interiorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> แคมเปญ :
                        <multiselect
                          v-model="campaignId"
                          label="nameTh"
                          type="search"
                          :options="rowsCampiagn"
                          open-direction="bottom"
                          placeholder="แคมเปญ"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.campaignId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.campaignId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.campaignId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>
                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ราคาสินค้า (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleTotalPrice"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleTotalPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleTotalPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleTotalPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (%) :
                        <input
                          type="number"
                          @change="calProd()"
                          style="text-align:right"
                          v-model="vehicleDcPer"
                          disabled
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.vehicleDcPer.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleDcPer.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleDcPrice"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleDcPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleDcPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleDcPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สุทธิ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleNetPrice"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleNetPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleNetPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleNetPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงื่อนไขการขาย :
                        <multiselect
                          v-model="saleCondition"
                          label="scName"
                          type="search"
                          :options="rowSaleCondition"
                          open-direction="bottom"
                          placeholder="เงื่อนไขการขาย"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleCondition.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleCondition.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงินดาวน์ (บาท) :
                        <input
                          @change="calProd()"
                          type="number"
                          style="text-align:right"
                          v-model="downPayment"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.downPayment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.downPayment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.downPayment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดจัดไฟแนนซ์ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="totalFinancing"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.totalFinancing.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.totalFinancing.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.totalFinancing.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ชำระงวดแรก :
                        <date-picker
                          v-model="installmentDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.installmentDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.installmentDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installmentDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สถาบันการเงิน :
                        <multiselect
                          v-model="fiId"
                          label="nameTh"
                          type="search"
                          :options="rowsFin"
                          open-direction="bottom"
                          placeholder="สถาบันการเงิน"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.fiId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.fiId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.fiId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ดอกเบี้ย/เดือน:
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="interest"
                          disabled
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.interest.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.interest.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interest.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> จำนวนงวด :
                        <input
                          @change="calProd()"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="numPeriods"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.numPeriods.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.numPeriods.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.numPeriods.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดชำระต่องวด (บาท):
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="installment"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.installment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.installment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ผู้แนะนำ :
                        <multiselect
                          v-model="recommenderId"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="bottom"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.recommenderId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.recommenderId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.recommenderId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ค่านำพา :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="referralFee"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.referralFee.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.referralFee.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.referralFee.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งทะเบียน<br />
                        <multiselect
                          v-model="licensePlateDelivery"
                          :options="postType"
                          label="nameTh"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.licensePlateDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.licensePlateDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.licensePlateDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งรถ <br />
                        <multiselect
                          v-model="productDelivery"
                          :options="postType"
                          label="nameTh"
                          disabled
                          :class="{
                            'is-invalid':
                              submitform && $v.productDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.productDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.productDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ประเภทภาษี <br />
                        <multiselect
                          v-model="taxType"
                          :options="taxTypeOptions"
                          label="nameTh"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.taxType.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.taxType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.taxType.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        สถานที่จัดส่ง :
                        <multiselect
                          v-model="addrId"
                          label="nameTh"
                          type="search"
                          :options="rowsArr"
                          :show-labels="false"
                          open-direction="bottom"
                          :custom-label="customLabelCustomerArr"
                          disabled
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.addrId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.addrId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.addrId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ :
                        <textarea
                          v-model="note"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          disabled
                          :class="{
                            'is-invalid': submitform && $v.note.$error,
                          }"
                        ></textarea>
                        <div
                          v-if="submitform && $v.note.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.note.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <p class="card-title-desc">รายการสินค้า</p>
            </div>
            <div class="col-6"></div>
          </div>
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab active @click="changeType('ACCY')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">อุปกรณ์เสริม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnAccy.length > 0">
                      <tr v-for="(anAccyItem, index) in qnAccy" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
            </b-tab>

            <b-tab @click="changeType('PM')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ของแถม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnPm.length > 0">
                      <tr v-for="(anAccyItem, index) in qnPm" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            disabled
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnPm.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>

            <b-tab @click="changeType('OTH')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่าใช้จ่ายอื่นๆ </span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสค่าใช้จ่าย</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnOth.length > 0">
                      <tr v-for="(anAccyItem, index) in qnOth" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.extraCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.extraNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnOth.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารายการค่าใช้จ่ายอื่นๆ
                    </label>
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="exCode"
                      :options="rowsExtra"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModalEx(exCode)"
                      :close-on-select="true"
                      :internal-search="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ผู้ค้ำประกัน</span>
              </template>
              <b-table hover :items="rowsGuarantor" :fields="fieldsGuarantor">
                <template #cell(index)="rowsGuarantor">
                  {{ rowsGuarantor.index + 1 }}
                </template>
                <!-- <template #cell(action)="rowsGuarantor">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="confirmDelGuarantor(rowsGuarantor.item.guarantorId)"
                  >
                    <i class="uil uil-trash font-size-14"></i>
                  </a>
                </template> -->
              </b-table>
              <div class="row">
                <span
                  v-if="this.rowsGuarantor.length === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
              </div>
              <!-- <div class="row">
                <div class="col-6">
                  <div class="mb-3 position-relative">
                    เพิ่มผู้ค้ำประกัน :
                    <multiselect
                      v-model="customerG"
                      label="nameTh"
                      type="search"
                      :options="rowsCustomers"
                      :show-labels="false"
                      open-direction="bottom"
                      :custom-label="customLabelCustomer"
                      @input="postGuarantor(customerG)"
                      placeholder=""
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่างวด</span>
              </template>

              <div class="table-responsive">
                <b-table hover :items="rowsInst" :fields="fieldsInst">
                  <template #cell(dueDate)="rowsInst">
                    <changeDate :date="rowsInst.item.dueDate"></changeDate>
                  </template>
                  <!-- <template #cell(action)="rowsInst">
                    <a
                      href="javascript:void(0);"
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="conformDelInst(rowsInst.item.iperiodId)"
                    >
                      <i class="uil uil-trash font-size-14"></i>
                    </a>
                  </template> -->
                </b-table>
              </div>
              <div class="row">
                <button
                  class="btn btn-primary text-center"
                  v-if="rowsInst.length < 1"
                >
                  <i class="uil uil-calender"></i>
                  ประมวลผลค่างวด
                </button>
              </div>
            </b-tab>
          </b-tabs>

          <div class="card-body">
            <h4 class="card-title">
              <h4>
                <strong>สรุปยอด</strong>
              </h4>
            </h4>
            <hr />
            <div class="row">
              <b>ยอดรวมค่าสินค้า</b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ราคาสินค้า (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="vehicleTotalPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleTotalPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleTotalPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleTotalPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="vehicleDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="vehicleDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="vehicleNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าอุปกรณ์เสริม</b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าอุปกรณ์เสริม (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="ttAccy"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.ttAccy.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttAccy.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttAccy.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="partDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="partDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="partNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าใช้จ่ายอื่น ๆ </b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าใช้จ่ายอื่น ๆ  (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="ttOTH"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.ttOTH.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttOTH.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttOTH.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="extraDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="extraDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align:right"
                    v-model="extraNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-2">
                <b
                  >รายละเอียด <br />
                  ใบเสนอขายรถ</b
                >
              </div>
              <div class="col-md-2">
                <div style="display: inline-block">
                  <div class="text-end">
                    ยอดรวม : <br />
                    ส่วนลดรวม(%) : <br />
                    ส่วนลดรวม(บาท) : <br />
                    ภาษีรวม(%) : <br />
                    ภาษีรวม(บาท) : <br />
                    สุทธิ : <br />
                    เงินดาวน์ : <br />
                    ยอดจัดไฟแนนซ์ :
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-end">
                {{
                  Number(qnTotalPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ Number(qnDcPer).toFixed(2) }}<br />
                {{
                  Number(qnDcPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ parseFloat(Number(vatPer)).toFixed(2) }} <br />
                {{ Number(qnVatPrice).toLocaleString() }} <br />
                {{ Number(parseFloat(qnGrandTotalPrice)).toLocaleString() }}
                <br />
                {{ Number(downPayment).toLocaleString() }} <br />
                {{ Number(totalFinancing).toLocaleString() }}
              </div>
              <div class="col-md-1 text-end">
                บาท<br />
                %<br />
                บาท <br />
                % <br />
                บาท <br />
                บาท <br />
                บาท <br />
                บาท
              </div>
              <!-- มูลค่ารวม -->
              <div
                class="col-5 bg-dark text-white m-0"
                style="border-radius:10px;margin:auto;justify "
              >
                <div class="row align-items-center m-0">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-3">
                        มูลค่ารวม :
                      </div>
                      <div class="col-6 font-size-24">
                        {{
                          Number(
                            parseFloat(this.qnGrandTotalPrice)
                          ).toLocaleString()
                        }}
                      </div>
                      <div class="col-3">
                        บาท
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <hr />
              <div class="text-end">
                <!-- <code v-show="statusQn != 'DFT'"
                  >ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว
                </code> -->
                <button
                  class="btn btn-danger"
                  @click="$refs.modalSubmit.show()"
                >
                  <!-- :disabled="statusSale != 'DFT'" -->
                  ยืนยันส่งมอบรถ
                </button>
                <!-- |

                <button
                  class="btn btn-success"
                  @click="putReturnPart()"
                  :disabled="statusQn == 'APV'"
                >
                  เสร็จสิ้น-กลับสู่หน้าหลัก
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <!-- //? Modal select Part -->
    <b-modal ref="modalSubmit" title="ยืนยันการส่งมอบรถ" hide-footer size="sm" centered>
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"> หมายเลขทะเบียนป้ายแดง (ถ้ามี)</label>
            <multiselect
              v-model="plateId"
              label="licensePlate"
              type="search"
              @search-change="getRedplate(licensePlate)"
              :options="rowRedplate"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="submitDetail()" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->

    <!-- //? Modal select Part -->
    <b-modal
      ref="modalAmoutEx"
      title="ระบุจำนวน"
      hide-footer
      size="sm"
      centered
    >
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.ex.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postEx(ex)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalMessage"
      id="modalMessage"
      title="รายละเอียดข้อความเพิ่มเติม"
      hide-footer
      size="lg"
      centered
      :state="nameState"
    >
      <b-overlay
        :show="overlayFlagMessage"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <div class="row">
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> หมายเหตุ <code>(ข้อความเพิ่มเติม)</code></label>
            <b-form-textarea
              id="textarea"
              v-model="textMessage"
              placeholder="หมายเหตุข้อความเพิ่มเติม"
              rows="10"
              max-rows="10"
            ></b-form-textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button @click="addMessage()" class="btn btn-primary">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal
      ref="modalCredit"
      title="สร้างส่งมอบรถ"
      hide-footer
      size="lg"
      centered
      :state="nameState"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
          <label> หมายเหตุ</label>
          <b-form-textarea
            id="textarea"
            v-model="inquireNoteCreate"
            placeholder="หมายเหตุส่งมอบรถ"
            rows="10"
            max-rows="10"
          ></b-form-textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="submitCredit()" class="btn btn-primary">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->
  </Layout>
</template>
